<template>
  <div>
    <div
        class="row"
        v-loading="loading"
    >
      <div class="col-5">
        <el-table
            :data="vehicleDetails1"
            :show-header="false"
        >
          <el-table-column prop="label">
            <template slot-scope="scope">
              <span :style="{color: scope.row.color}">
                <font-awesome-icon :icon="scope.row.icon" />&nbsp;
                <b>{{ $t(scope.row.label) }}</b>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="value">
            <template slot-scope="scope">
              <template v-if="scope.row.value">
                {{ scope.row.value }}
              </template>
              <span
                  class="no-data"
                  v-else
              >
                {{ $t('system.no_data') }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-7">
        <el-table
            :data="vehicleDetails2"
            :show-header="false"
        >
          <el-table-column prop="label">
            <template slot-scope="scope">
              <span :style="{color: scope.row.color}">
                <font-awesome-icon :icon="scope.row.icon" />&nbsp;
                <b>{{ $t(scope.row.label) }}</b>
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="value">
            <template slot-scope="scope">
              <template v-if="scope.row.key === 'availability' ">
                {{ !!scope.row.value ? $t('system.yes') : $t('system.no') }}
              </template>
              <template v-else-if="scope.row.value">
                {{ scope.row.value }}
              </template>
              <span
                  class="no-data"
                  v-else
              >
                {{ $t('system.no_data') }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-divider />
    <VehicleUnavailableTerms
        :vehicleId="id"
        class="mb-5"
    />
  </div>
</template>

<script>
import axios from 'axios';
import notification from '../../../notification/notify';

export default {
  name: 'VehicleDetails',
  components: {
    VehicleUnavailableTerms: () => import('@/components/WorkOrderComponents/Vehicles/VehicleUnavailableTerms'),
  },
  props: {
    id: {
      type: [String, Number],
    },
  },
  created() {
    this.fetchVehicleDetails();
  },
  data: () => ({
    loading: false,
    vehicleDetails1: [
      {
        key: 'vehicle_no',
        label: 'system.vehicle_no',
        icon: 'clipboard-list',
        value: '',
      }, {
        key: 'name',
        label: 'system.name',
        icon: 'align-justify',
        value: '',
      }, {
        key: 'year',
        label: 'system.year',
        icon: 'calendar-alt',
        value: '',
      }, {
        key: 'make',
        label: 'system.make',
        icon: 'car',
        value: '',
      }, {
        key: 'model',
        label: 'system.model',
        icon: 'car',
        value: '',
      }, {
        key: 'plate',
        label: 'system.plate',
        icon: 'registered',
        value: '',
      },
      {
        key: 'vin',
        label: 'system.vin',
        icon: 'file-alt',
        value: '',
      }, {
        key: 'max_height',
        label: 'system.max_height',
        icon: 'arrows-alt-v',
        value: '',
      }, {
        key: 'i_pass',
        label: 'system.i_pass',
        icon: 'clipboard',
        value: '',
      }, {
        key: 'external_id',
        label: 'system.external_id',
        icon: 'clipboard',
        value: '',
      },
    ],
    vehicleDetails2: [
      {
        key: 'registered_at',
        label: 'system.registered_at',
        icon: 'calendar-alt',
        value: '',
      }, {
        key: 'last_safety_inspection_from',
        label: 'system.last_safety_inspection_from',
        icon: 'user-shield',
        value: '',
      }, {
        key: 'last_safety_inspection_to',
        label: 'system.last_safety_inspection_to',
        icon: 'user-shield',
        value: '',
      }, {
        key: 'emissions_tested_at',
        label: 'system.emissions_tested_at',
        icon: 'fire',
        value: '',
      },
      {
        key: 'oil_due',
        label: 'system.oil_due',
        icon: 'tint',
        value: '',
      }, {
        key: 'mileage_due',
        label: 'system.mileage_due',
        icon: 'road',
        value: '',
      }, {
        key: 'note',
        label: 'system.note',
        icon: 'comment-alt',
        value: '',
      },
      {
        key: 'availability',
        label: 'system.availability',
        icon: 'check',
        value: '',
      },
    ],
  }),
  methods: {
    setVehicleDetails(tableName, values) {
      this[tableName] = this[tableName].map(item => {
        item.value = values[item.key] || '';
        return item;
      });
    },
    async fetchVehicleDetails() {
      try {
        this.loading = true;
        const {data} = await axios.get(`workorders/vehicles/${this.id}`);
        this.setVehicleDetails('vehicleDetails1', data);
        this.setVehicleDetails('vehicleDetails2', data);
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
